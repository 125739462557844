import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-tags',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tags.component.html',
  styleUrl: './tags.component.scss'
})
export class TagsComponent {
  @Input() tags: string[] = [];

  get firstTag(): string | null {
    if (this.tags.length === 0) return null;
    const tag = this.tags[0];
    return tag.length > 10 ? `${tag.slice(0, 10)}...` : tag;
  }

  get otherTags(): number {
    return this.tags.length > 1 ? this.tags.length - 1 : 0;
  }
}
