import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  SubcontractorModel,
} from '../../_models/subcontractors.model';
import { environment } from '../../../environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class SelectedSubcontractorService {
  private apiUrl = environment.urlApi;
  private selectedSubcontractorId: string = '';
  private companyId: string = '';
  private selectedSubcontractorTags: string[] = [];
  private selectedSubcontractorState: boolean = false;
  private selectedSubcontractorLastUpdate: Date = new Date();

  constructor(private http: HttpClient) {}

  setSelectedIds(companyId: string, subcontractorId: string): void {
    this.companyId = companyId;
    this.selectedSubcontractorId = subcontractorId;
  }

  setSelectedSubcontractorInfo(subcontractorTags: string[], subcontractorState: boolean, subcontractorLastUpdate: Date): void {
    this.selectedSubcontractorTags = subcontractorTags;
    this.selectedSubcontractorState = subcontractorState;
    this.selectedSubcontractorLastUpdate = subcontractorLastUpdate;
  }

  getSelectedSubcontractorInfo(): { subcontractorTags: string[]; subcontractorState: boolean; subcontractorLastUpdate: Date } {
    return { subcontractorTags: this.selectedSubcontractorTags, subcontractorState: this.selectedSubcontractorState, subcontractorLastUpdate: this.selectedSubcontractorLastUpdate };
  }

  getSelectedIds(): { companyId: string; subcontractorId: string } {
    return { companyId: this.companyId, subcontractorId: this.selectedSubcontractorId };
  }

  getSubcontractorDetail(): Observable<SubcontractorModel> {
    if (!this.companyId || !this.selectedSubcontractorId) {
      return throwError(() => new Error('Company ID and Subcontractor ID must be set before fetching details.'));
    }

    return this.http.get<SubcontractorModel>(
      `${this.apiUrl}api/v1/registers/${this.companyId}/subcontractors/${this.selectedSubcontractorId}`
    ).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: any): Observable<never> {
    console.error('HTTP request error:', error);
    return throwError(() => new Error('An error occurred, please try again later.'));
  }
}